<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 66.425 46.705"
  >
    <path
      d="M66.425 31.137L63 33l3.425 2.024v1.946a5.83 5.83 0 01-5.838 5.838H5.838A5.83 5.83 0 010 36.975v-5.838l2.1-1.946L0 27.245v-3.892l2.18-4.788L0 7.784V5.838A5.83 5.83 0 015.838 0h48.443A5.793 5.793 0 0159.5 3.23l2.3 4.554-6.662 11.287 11.287 8.173zm0 0"
      :style="iconStyle"
    />
    <path
      class="b"
      d="M66.425 31.137L62.999 33l3.425 2.024v1.946a5.83 5.83 0 01-5.838 5.838H25.299V0h28.983a5.793 5.793 0 015.217 3.23l2.3 4.554-6.656 11.287 11.287 8.173zm0 0"
    />
    <path
      class="b"
      d="M66.425 23.352v3.892h-14.4l-3.892-3.892H0V7.784h61.794l1.946 3.892-.817 6.733zm0 0"
    />
    <path
      d="M66.425 23.352v3.892h-14.4l-3.892-3.892H25.299V7.784h36.495l1.946 3.892-.817 6.733zm0 0"
      :style="iconStyle"
    />
    <path class="d" d="M66.425 31.137v3.892h-5.838a1.946 1.946 0 010-3.892zm0 0" />
    <path
      d="M42.813 29.191a1.927 1.927 0 01-1.946 1.946H0v-3.892h40.867a1.927 1.927 0 011.946 1.946zm0 0"
      :style="iconStyle"
    />
    <path
      class="f"
      d="M13.622 46.705a5.838 5.838 0 115.838-5.838 5.845 5.845 0 01-5.838 5.838zm0 0"
    />
    <path
      class="f"
      d="M25.298 35.029a5.838 5.838 0 105.838 5.838 5.83 5.83 0 00-5.838-5.838zm0 0"
    />
    <path
      class="g"
      d="M52.803 46.705a5.838 5.838 0 115.838-5.838 5.845 5.845 0 01-5.838 5.838zm0 0"
    />
    <path
      class="h"
      d="M66.231 16.658a2.125 2.125 0 01.195.856v5.838h-12.8l-2.769-2.763v-8.912H63.74zm0 0"
    />
    <path
      d="M3.892 11.676h7.784v7.784H3.892zm11.676 0h7.784v7.784h-7.784zm0 0"
      :style="iconStyle"
    />
    <path class="h" d="M27.245 11.676h7.784v7.784h-7.784zm11.676 0h8.044v7.784h-8.044zm0 0" />
    <path
      d="M42.813 29.191a1.927 1.927 0 01-1.946 1.946H25.299v-3.892h15.568a1.927 1.927 0 011.946 1.946zm0 0"
      :style="iconStyle"
    />
    <path
      class="g"
      d="M31.137 40.867a5.83 5.83 0 01-5.838 5.838V35.029a5.83 5.83 0 015.838 5.838zm0 0"
    />
    <path
      d="M13.622 42.813a1.946 1.946 0 111.946-1.946 1.947 1.947 0 01-1.946 1.946zm13.623-1.946a1.946 1.946 0 11-1.946-1.946 1.927 1.927 0 011.946 1.946zm0 0"
      :style="iconStyle"
    />
    <path
      class="d"
      d="M52.803 42.813a1.946 1.946 0 111.946-1.946 1.947 1.947 0 01-1.946 1.946zm-25.558-1.946a1.927 1.927 0 01-1.946 1.946v-3.892a1.927 1.927 0 011.946 1.946zm0 0"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.188',
    },
    width: {
      type: String,
      default: '4.125',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
